import * as React from 'react';
import Icon from '@ant-design/icons';
import getConfig from 'next/config';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

interface SVGRProps {
    title?: string;
    titleId?: string;
}

function AESmallLogotypeSvg({
    title,
    titleId,
    ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 23.459 13.824"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g data-name="Group 1130">
                <g data-name="Group 955">
                    <path
                        data-name="Path 328"
                        d="M8.303 4.152v-.048c0-.15-.056-.2-.2-.2-.206 0-.331 0-.907.566a6.266 6.266 0 01-.609.505c.441-.056.965-.1 1.637-.123a3.105 3.105 0 00.087-.587.958.958 0 00-.006-.112m.635-1.989c-2.9 0-4.408.809-4.609 1.612a.449.449 0 00.041.4.9.9 0 00.693.227c.418 0 .716-.106 1.375-.724a2.222 2.222 0 011.664-.873 1.248 1.248 0 011.293 1.271v-.044.048c0 .065.01.127.01.189a5.9 5.9 0 01-.206 1.233l-.094.412-.422.015a15.044 15.044 0 00-2.694.252c-2.143.431-3.438 1.481-3.856 3.122a3.255 3.255 0 00-.1.786 1.931 1.931 0 00.375 1.206 2.3 2.3 0 001.876.755 3.37 3.37 0 002.725-1.2l.248-.27a1.5 1.5 0 01-.942.376 1.04 1.04 0 01-.838-.4 1.742 1.742 0 01-.187-1.516 3.179 3.179 0 013.049-2.6l.745-.04-.42 1.65a6.044 6.044 0 01-.857 1.93l.308-.339-.154 1.635a3.514 3.514 0 00-.019.347.915.915 0 00.011.166 1.132 1.132 0 00.154.008h3.031c.507 0 .595-.179.649-.4.025-.1.009-.114-.01-.129l-.029-.025-.2-.21c-.518-.487-.426-.857-.125-2.041l.925-3.644a2.5 2.5 0 00-.212-2.2c-.505-.649-1.579-.978-3.193-.978M6.353 9.308a1.435 1.435 0 00-.05.353.5.5 0 00.031.2c.268-.027.934-.757 1.271-2.082l.019-.077A2.165 2.165 0 006.353 9.31m17.112-4.863V0H0v13.824h23.459v-3.628H15.84V8.703h7.619V5.387H15.84V4.44zM13.412 5.618l-.927 3.644a9 9 0 00-.21.953l.056.052.187.2-.054-.05a1.172 1.172 0 01.385 1.252 1.573 1.573 0 01-1.709 1.223H8.109a1.248 1.248 0 01-.971-.343 1.011 1.011 0 01-.127-.231 4.474 4.474 0 01-2.723.834 3.351 3.351 0 01-2.739-1.177 3 3 0 01-.605-1.876 4.349 4.349 0 01.137-1.057A5.1 5.1 0 014.567 5.46a1.712 1.712 0 01-1.052-.6 1.435 1.435 0 01-.3-.9 1.9 1.9 0 01.058-.449c.389-1.527 2.509-2.438 5.672-2.438 1.97 0 3.334.472 4.056 1.4a2.914 2.914 0 01.587 1.847 5.288 5.288 0 01-.175 1.292m.373-3.617l-.015.008.05-.031-.035.023"
                        fill="#004077"
                    />
                    <path
                        data-name="Path 329"
                        d="M3.226 3.512a1.782 1.782 0 00-.058.449 1.447 1.447 0 00.3.894 1.709 1.709 0 001.053.6 5.1 5.1 0 00-3.484 3.582 4.358 4.358 0 00-.139 1.057 3.019 3.019 0 00.6 1.878 3.358 3.358 0 002.741 1.177 4.468 4.468 0 002.723-.836.946.946 0 00.125.231 1.253 1.253 0 00.974.343h3.029a1.576 1.576 0 001.712-1.223 1.174 1.174 0 00-.385-1.252l.054.052-.189-.2s-.038-.036-.054-.054a8.438 8.438 0 01.21-.951l.926-3.644a5.229 5.229 0 00.177-1.292 2.926 2.926 0 00-.587-1.849c-.722-.93-2.086-1.4-4.056-1.4-3.164 0-5.285.911-5.672 2.438m1.1.672a.458.458 0 01-.043-.4c.2-.8 1.71-1.612 4.611-1.612 1.614 0 2.687.329 3.193.978a2.506 2.506 0 01.212 2.2l-.926 3.644c-.306 1.2-.4 1.564.148 2.064l-.029-.029.2.218.031.023c.016.014.035.031.01.129-.056.219-.143.4-.651.4H8.053a1.155 1.155 0 01-.154-.008.979.979 0 01-.01-.169c0-.083 0-.193.019-.345L8.06 9.64l-1.1 1.219a3.369 3.369 0 01-2.725 1.2 2.3 2.3 0 01-1.876-.755 1.935 1.935 0 01-.374-1.207 3.16 3.16 0 01.1-.786c.418-1.641 1.714-2.692 3.854-3.124a15.291 15.291 0 012.7-.252l.422-.015.094-.412a5.852 5.852 0 00.2-1.233 1.814 1.814 0 00-.01-.187 1.244 1.244 0 00-1.292-1.271 2.223 2.223 0 00-1.666.872c-.657.618-.957.724-1.375.724a.892.892 0 01-.691-.225m2.825.289c.577-.566.7-.566.907-.566.143 0 .2.054.2.2v.046a1.052 1.052 0 01.006.112 3.082 3.082 0 01-.087.586c-.672.029-1.2.069-1.639.123a5.726 5.726 0 00.611-.505m2.2-.389v-.046.044"
                        fill="#fff"
                    />
                    <path
                        data-name="Path 330"
                        d="M13.723 2.003l.015-.006a.351.351 0 00.035-.025z"
                        fill="#fff"
                    />
                    <path
                        data-name="Path 332"
                        d="M8.338 6.635a3.172 3.172 0 00-3.049 2.6 1.749 1.749 0 00.187 1.519 1.033 1.033 0 00.836.4c1.106 0 1.993-1.51 2.348-2.912l.42-1.652zM6.3 9.861a1.47 1.47 0 01.05-.356 2.164 2.164 0 011.271-1.608l-.021.077c-.337 1.325-1 2.055-1.269 2.082a.454.454 0 01-.031-.2"
                        fill="#fff"
                    />
                    <path
                        data-name="Path 333"
                        d="M8.102 12.423c-.622 0-.776-.214-.691-1.119a3.912 3.912 0 01-3.13 1.381 2.544 2.544 0 01-2.677-3.43c.472-1.858 1.916-3.049 4.279-3.524a15.23 15.23 0 012.783-.262 3.8 3.8 0 00.183-1.288.709.709 0 00-.747-.738c-.435 0-.7.143-1.281.716a2.377 2.377 0 01-1.758.88c-.934 0-1.466-.524-1.267-1.308.316-1.24 2.28-2.026 5.142-2.026 3.257 0 4.584 1.31 3.937 3.858l-.927 3.646c-.308 1.213-.3 1.261-.013 1.522l.179.191a.627.627 0 01.2.691 1.049 1.049 0 01-1.179.809zm.262-5.358a2.633 2.633 0 00-2.548 2.19c-.181.716.017 1.24.5 1.24.663 0 1.454-1.073 1.818-2.5z"
                        fill="#004077"
                    />
                    <g data-name="Group 954">
                        <g data-name="Group 953">
                            <path
                                data-name="Path 334"
                                d="M8.102 12.423c-.622 0-.776-.214-.691-1.119a3.912 3.912 0 01-3.13 1.381 2.544 2.544 0 01-2.677-3.43c.472-1.858 1.916-3.049 4.279-3.524a15.23 15.23 0 012.783-.262 3.8 3.8 0 00.183-1.288.709.709 0 00-.747-.738c-.435 0-.7.143-1.281.716a2.377 2.377 0 01-1.758.88c-.934 0-1.466-.524-1.267-1.308.316-1.24 2.28-2.026 5.142-2.026 3.257 0 4.584 1.31 3.937 3.858l-.927 3.646c-.308 1.213-.3 1.261-.013 1.522l.179.191a.627.627 0 01.2.691 1.049 1.049 0 01-1.179.809zm.262-5.358a2.633 2.633 0 00-2.548 2.19c-.181.716.017 1.24.5 1.24.663 0 1.454-1.073 1.818-2.5z"
                                fill="none"
                                stroke="#fff"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

function MMSmallLogotypeSvg({
    title,
    titleId,
    ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 32 15" aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                id="Path_258"
                d="M12.3,10L10,16.4H9.6L7.3,10l-0.7,6.2H2.3L4.3,2.6h3.8l1.8,4.9l1.8-4.9h3.8l1.4,11l1.6-11h3.8
	L24,7.4l1.8-4.9h3.8l1.7,13.6H27L26.4,10l-2.3,6.4h-0.4L21.5,10l-0.7,6.2h-7.9L12.3,10z"
            />
        </svg>
    );
}

function ETSmallLogotypeSvg({
    title,
    titleId,
    ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 50 25" aria-labelledby={titleId} {...props}>
            {title ? <title id={titleId}>{title}</title> : null}
            <path
                id="Path_70"
                d="M16.6,17.9c0-6.2-2-10-7.9-10C4.6,7.8,1,10.5,1,16.6c0,6.3,3.6,8.3,7.8,8.3c3.3,0,6.6-0.4,7.6-5.5
	h-4.9c-0.1,1.3-1.1,2.2-2.4,2.2c-0.1,0-0.1,0-0.2,0c-1.9,0-3-1.3-3.1-3.8H16.6z M11.7,14.6H5.8c0.2-1.6,0.8-3.4,3-3.4
	C10.4,11.1,11.7,12,11.7,14.6"
            />
            <path
                id="Path_71"
                d="M15.9,12.3h2.9V19c0,3.9,1.9,5.7,5.4,5.7c1,0,2.1-0.1,3.1-0.4v-3.9c-0.6,0.1-1.2,0.2-1.8,0.2
	c-1.8,0-2.1-1.2-2.1-3.4v-5h3.9V8.4h-3.9V3l-4.6,2.2v3.2h-2.9V12.3"
            />
            <path id="Path_72" d="M28.9,0.9h19.3v26.3H28.9V0.9z" />
            <path
                id="Path_73"
                style={{ fill: 'white' }}
                d="M45.4,25L40.2,3.6h-3.3L31.7,25h3.5l0.8-4.2H41l0.9,4.2H45.4z M40.4,17.7h-3.7l1.8-8.9L40.4,17.7"
            />
        </svg>
    );
}

function RMSmallLogotypeSvg({
    title,
    titleId,
    ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width="1em"
            height="1em"
            aria-labelledby={titleId}
            viewBox="-15 -8 40 40"
            {...props}
            style={{ fill: '#000' }}
        >
            {title ? <title id={titleId}>{title}</title> : null}

            <g id="logo_RM_x5F_minimal">
                <path
                    d="M-326.9,474.3h2.9V465c0-1.5,0-3.4,0-4h0.1c0.2,1.9,0.3,3.1,0.4,4.3l1.4,9h1.9l1.3-9c0.1-1.1,0.3-2.8,0.4-4.3h0.1
		c0,1.1,0,2.5,0,4v9.3h3.4v-20.7h-4.3l-1.7,12.9h0l-1.7-12.9h-4.3V474.3z"
                />
                <path
                    fill="#ED1C24"
                    d="M-334.9,464.1v-7.4h0.6c1,0,1.5,0.6,1.5,3.5c0,3.1-0.5,3.9-1.5,3.9H-334.9z M-338.4,474.3h3.5v-7.5h0.7
		l1.8,7.5h3.6l-2.2-8.7c1.2-0.8,2-2.6,2-5.5c0-4.8-2.2-6.6-5.1-6.6h-4.3V474.3z"
                />
                <path
                    d="M-305.9,455.7c-1-0.3,0.9,2.5,0.1,1.9c-3-2.4-8.7-8.2-11.9-10.2c-6.5-4.2-14-3.9-19.7,1.4c-8.7,8-18.8,9.2-29.7,7.6
		c-9.5-1.4-18.9-3.3-28.4-4.5c-4-0.5-7.7-0.2-12.5,0.4c4.4-2.3,9-2.9,13.5-2.5c8,0.8,16,2.1,24,3c3.6,0.4,7.3,0.6,11,0.5
		c5.4-0.1,10.4-1.8,14.7-5.3c2.3-1.9,5-4.1,7.7-5.6c7.6-4.4,15.1-3.9,22.4,1.2c1.7,1.2,3.4,2.4,5,3.7"
                />
                <path
                    d="M-320.3,439.1c1.7-2,3.3-3.8,5-5.8c-5.6-1.9-11-1.6-16.4,0.1c-8.5,2.6-15.6,7.7-22.9,12.6c-2.5,1.7-9.4,6.2-10.1,6.6
		c-1.8,0-2.8-0.2-5-0.4c2.9-2.4,5.7-4.7,8.4-6.8c8.9-7,18.2-13.4,29.4-16.2c9.8-2.4,19.1-0.9,27.8,4.3c0.4,0.3,0.9,0.6,1.3,1.1
		c-3.7,0.6-4,0.7-5.8,1.4c-1.2,0.5-2.7,1.4-3.6,2.4c-1,1-1.6,1.4-2.8,3C-316.6,440.5-316.5,440.3-320.3,439.1"
                />
                <path
                    fill="#ED1C24"
                    d="M-376.2,436c8.8-3.5,22.7-6.6,32.3-5.9c-1.4,0.5-2.9,1.3-4.6,2.3c-7,4.6-14,9.2-20.9,14
		c-2.7,1.9-4.9,3-8.5,1.9c-8-2.4-16.4-1.8-24.6-0.8c-0.4,0.1-0.9,0.1-1.3,0.1c-0.1-0.2-2.1,0.1-2.1-0.1c3.5-0.9,9-2.9,12.6-3.2
		c5.8-1,11.8-0.2,17.7,0.1c2.2,0.1,4.4-1.2,6-2.9c-6.7-1.8-16.4,0.1-22.7-0.5c0-0.3,1.8-0.4,3.1-0.7c4.6-0.7,9.3-1.6,13.9-2.1
		c2.5-0.3,5-0.2,7.5,0c2.9,0.3,4-0.1,6.2-2.6c-1.4-0.5-2.7-0.4-4.7-0.3C-368.3,435.1-374.3,436-376.2,436"
                />
                <path
                    d="M-370.1,456.8c-5.8,4.7-18.8,4.8-30.5-0.1c2,0.4,4,0.9,6,1.3c6,1.2,11.8,0.2,17.6-0.8c2.2-0.6,2.1-0.6,3-1
		C-372.4,456.4-370.5,456.7-370.1,456.8"
                />
                <path
                    d="M-298.4,438.2c-5.3,0-9.6,4.3-9.6,9.6c0,5.3,4.3,9.6,9.6,9.6c5.3,0,9.6-4.3,9.6-9.6C-288.9,442.5-293.2,438.2-298.4,438.2
		 M-298.4,460.1c-6.8,0-12.4-5.5-12.4-12.4c0-6.8,5.5-12.4,12.4-12.4c6.8,0,12.4,5.5,12.4,12.4
		C-286.1,454.5-291.6,460.1-298.4,460.1"
                />
                <path
                    d="M-298.4,441c-3.7,0-6.8,3-6.8,6.8c0,3.7,3,6.8,6.8,6.8c3.7,0,6.8-3,6.8-6.8C-291.7,444-294.7,441-298.4,441 M-298.4,455.4
		c-4.2,0-7.7-3.4-7.7-7.7c0-4.2,3.4-7.7,7.7-7.7c4.2,0,7.7,3.4,7.7,7.7C-290.7,452-294.2,455.4-298.4,455.4"
                />
                <path
                    d="M12.1,22.6H15v-9.3c0-1.5,0-3.4,0-4H15c0.2,1.9,0.3,3.1,0.4,4.3l1.4,9h1.9l1.3-9c0.1-1.1,0.3-2.8,0.4-4.3h0.1
		c0,1.1,0,2.5,0,4v9.3H24V1.9h-4.3L18,14.7h0L16.4,1.9h-4.3V22.6z"
                />
                <path
                    fill="#ED1C24"
                    d="M4.1,12.4V5.1h0.6c1,0,1.5,0.6,1.5,3.5c0,3.1-0.5,3.9-1.5,3.9H4.1z M0.5,22.6h3.5v-7.5h0.7l1.8,7.5h3.6
		l-2.2-8.7c1.2-0.8,2-2.6,2-5.5c0-4.8-2.2-6.6-5.1-6.6H0.5V22.6z"
                />
            </g>
            <g id="znak_RM">
                <path
                    d="M-235.3,656.4c-2.9-0.9,2.5,7.4,0.3,5.6c-8.9-7-25.5-24-34.9-30c-19.1-12.3-41.1-11.4-57.8,4.1c-25.4,23.5-55,27-87,22.2
		c-27.8-4.1-55.4-9.7-83.3-13.2c-11.7-1.5-22.5-0.6-36.6,1.3c13-6.6,26.2-8.5,39.5-7.3c23.5,2.2,46.9,6.1,70.4,8.8
		c10.7,1.2,21.5,1.8,32.2,1.6c16-0.3,30.4-5.2,43-15.7c6.9-5.7,14.8-12.1,22.5-16.5c22.4-12.8,44.2-11.6,65.8,3.6
		c5,3.5,9.9,7.1,14.7,10.7"
                />
                <path
                    d="M-277.7,607.5c5-5.9,9.5-11.2,14.5-17c-16.3-5.5-32.2-4.6-48.1,0.2c-25,7.5-45.8,22.6-67.1,36.8
		c-7.5,5-27.6,18.3-29.7,19.4c-5.2,0-8.2-0.5-14.6-1.1c8.5-6.9,16.9-13.8,24.6-19.9c26.1-20.5,53.2-39.2,86.1-47.4
		c28.8-7.1,56-2.5,81.4,12.5c1.1,0.8,2.7,1.8,3.9,3.2c-10.9,1.8-11.6,2.1-16.9,4.2c-3.6,1.4-7.9,4.2-10.6,7c-3,3-4.6,4.2-8.1,8.9
		C-266.8,611.7-266.4,611.2-277.7,607.5"
                />
                <path
                    fill="#ED1C24"
                    d="M-441.3,598.5c25.7-10.4,66.6-19.2,94.7-17.3c-4.2,1.4-8.4,3.8-13.5,6.6c-20.5,13.5-41.1,26.9-61.2,41
		c-7.9,5.5-14.5,8.7-25,5.6c-23.6-7-48-5.4-72.2-2.4c-1.2,0.2-2.5,0.2-3.7,0.3c-0.2-0.7-6.1,0.3-6.2-0.4c10.4-2.7,26.4-8.6,36.9-9.4
		c17-2.8,34.6-0.7,51.9,0.3c6.3,0.3,13-3.5,17.7-8.5c-19.6-5.2-47.9,0.2-66.7-1.6c-0.1-0.9,5.4-1.2,9-2.2
		c13.6-2.1,27.1-4.6,40.8-6.1c7.3-0.8,14.8-0.7,22.1,0.1c8.4,0.9,11.7-0.3,18.1-7.7c-4.2-1.4-8-1.2-13.8-1
		C-418.2,596-435.9,598.6-441.3,598.5"
                />
                <path
                    d="M-423.5,659.5c-17.1,13.7-55.1,14-89.4-0.3c5.9,1.3,11.8,2.5,17.7,3.8c17.5,3.6,34.6,0.7,51.5-2.5c6.4-1.7,6.1-1.7,8.9-3
		C-430.2,658.3-424.7,659.2-423.5,659.5"
                />
                <path
                    d="M-213.4,605c-15.4,0-28,12.6-28,28c0,15.4,12.6,28,28,28c15.4,0,28-12.6,28-28C-185.5,617.5-198,605-213.4,605
		 M-213.4,669.2c-20,0-36.2-16.2-36.2-36.2c0-20,16.2-36.2,36.2-36.2c20,0,36.2,16.2,36.2,36.2
		C-177.2,652.9-193.5,669.2-213.4,669.2"
                />
                <path
                    d="M-213.4,613.2c-10.9,0-19.8,8.9-19.8,19.8c0,10.9,8.9,19.8,19.8,19.8c10.9,0,19.8-8.9,19.8-19.8
		C-193.7,622.1-202.5,613.2-213.4,613.2 M-213.4,655.5c-12.4,0-22.5-10.1-22.5-22.5c0-12.4,10.1-22.5,22.5-22.5
		c12.4,0,22.5,10.1,22.5,22.5C-190.9,645.4-201,655.5-213.4,655.5"
                />
            </g>
            <g id="logo_RM_x5F_2">
                <path
                    style={{ fill: '#000' }}
                    d="M-151.4,277h4.5v-8.5l4.1-17.4h-4l-1.3,6.9c-0.3,1.5-0.6,3.3-0.7,4.6h-0.1c-0.1-1.3-0.5-3.1-0.7-4.6l-1.3-6.9h-4.6l4,17.4
		V277z M-170.8,277.1h4.2l1.7-16.5h0l1.7,16.5h4.2l3.3-26h-4.1l-1,12.2c-0.2,2.1-0.2,3.4-0.3,5.5h0c-0.1-2.1-0.2-3.6-0.5-5.6
		l-1.3-12.2h-3.2l-1.5,12.3l-0.5,5.2c0-0.9,0-0.1,0,0.1c-0.1-2-0.2-3.8-0.3-5.4l-1-12.3h-4.4L-170.8,277.1z M-180.7,273.2
		c-1.5,0-1.8-2.3-1.8-9.1c0-6.8,0.2-9,1.8-9c1.5,0,1.8,2.2,1.8,9C-178.9,270.8-179.2,273.2-180.7,273.2 M-180.7,277.2
		c4.3,0,6.4-3.6,6.4-13.2s-2.1-13.2-6.4-13.2s-6.4,3.6-6.4,13.2S-185,277.2-180.7,277.2 M-196.4,277h9.2v-3.8h-4.7v-22.1h-4.5V277z
		 M-209.8,277h4.4v-8.9l0.6-2l2.5,10.8h4.5l-4-15.8l3.5-10.1h-3.9l-1.8,5.4c-0.6,1.7-1,3.5-1.5,5.5v-11h-4.4V277z M-219.3,277h4.5
		v-8.5l4.1-17.4h-4l-1.3,6.9c-0.3,1.5-0.6,3.3-0.7,4.6h-0.1c-0.1-1.3-0.5-3.1-0.7-4.6l-1.3-6.9h-4.5l4,17.4V277z M-228.9,277.2
		c4.9,0,5.7-4.4,6-9.7l-4.3-0.2c-0.1,4.8-0.5,5.8-1.7,5.8c-1.5,0-1.7-2.5-1.7-9.1c0-6.5,0.2-9,1.7-9c1.1,0,1.5,1.3,1.6,6l4.3-0.2
		c-0.1-7.7-1.7-9.9-5.8-9.9c-4.3,0-6.3,3.5-6.3,13.2C-235.2,273.8-233.2,277.2-228.9,277.2 M-242.8,273.2c-1.5,0-1.8-2.3-1.8-9.1
		c0-6.8,0.2-9,1.8-9c1.5,0,1.8,2.2,1.8,9C-241.1,270.8-241.3,273.2-242.8,273.2 M-242.8,277.2c4.3,0,6.4-3.6,6.4-13.2
		s-2.1-13.2-6.4-13.2s-6.4,3.6-6.4,13.2S-247.1,277.2-242.8,277.2 M-257.2,277h4.5V255h3.4v-4h-11.3v4h3.4V277z M-267.4,273.2
		c-1.5,0-1.8-2.3-1.8-9.1c0-6.8,0.2-9,1.8-9c1.5,0,1.8,2.2,1.8,9C-265.7,270.8-265.9,273.2-267.4,273.2 M-267.4,277.2
		c4.3,0,6.4-3.6,6.4-13.2s-2.1-13.2-6.4-13.2c-4.3,0-6.4,3.6-6.4,13.2S-271.8,277.2-267.4,277.2 M-290.8,277h3.7v-11.6
		c0-1.9,0-4.3,0-5h0.1c0.2,2.4,0.4,3.9,0.6,5.4l1.7,11.2h2.3l1.7-11.3c0.2-1.4,0.4-3.5,0.6-5.4h0.1c0,1.4,0,3.2,0,5V277h4.3v-25.9
		h-5.4l-2.1,16.1h0l-2.1-16.1h-5.4V277z"
                />
                <path
                    fill="#ED1C24"
                    d="M-237,247h4.4v-8.9l0.6-2l2.5,10.8h4.5l-4-15.8l3.5-10.1h-3.9l-1.8,5.4c-0.6,1.7-1,3.5-1.5,5.5v-11h-4.4V247z
		 M-248.9,247h9.5v-3.8h-4.9v-8.1h3.3v-3.7h-3.3V225h4.8v-4h-9.3V247z M-263.7,247h3.9v-8.2c0-1.4,0-5.4-0.2-7h0.1
		c0.4,2.5,1.2,4.8,1.8,7l2.3,8.2h3.7v-25.9h-3.9v8c0,1.8,0,4.6,0.2,7.1h-0.1c-0.4-2.4-1-5-1.5-7.2l-2-7.9h-4.2V247z M-274.3,247h4.5
		v-8.5l4.1-17.4h-4l-1.3,6.9c-0.3,1.5-0.6,3.3-0.7,4.6h-0.1c-0.1-1.3-0.5-3.1-0.7-4.6l-1.3-6.9h-4.5l4,17.4V247z M-286.4,234.2V225
		h0.8c1.3,0,1.9,0.8,1.9,4.3c0,3.8-0.7,4.9-1.9,4.9H-286.4z M-290.8,247h4.4v-9.4h0.8l2.3,9.4h4.4l-2.8-10.8c1.5-1,2.5-3.3,2.5-6.8
		c0-6-2.8-8.2-6.3-8.2h-5.3V247z"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-132.5,255.4c-0.8-0.5,0.2,2.5-0.4,1.8c-2.2-2.9-6.1-9.6-8.6-12.2c-5-5.4-12.1-6.8-18.5-3.3c-9.8,5.4-19.4,4.2-29.1,0.2
		c-8.4-3.5-16.7-7.4-25.2-10.7c-3.6-1.4-7-2-11.6-2.5c4.6-1.1,8.9-0.6,13,0.8c7.2,2.6,14.3,5.6,21.5,8.3c3.3,1.2,6.6,2.2,10,3
		c5.1,1.2,10,0.7,14.8-1.6c2.6-1.3,5.6-2.7,8.4-3.4c8.1-2.3,14.8-0.2,20.4,6.3c1.3,1.5,2.5,3,3.8,4.5"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-142,236.7c2-1.5,3.9-2.8,5.9-4.2c-4.7-3-9.8-4-15.2-3.7c-8.5,0.4-16.2,3.5-24,6.3c-2.7,1-10.1,3.6-10.9,3.8
		c-1.6-0.4-2.5-0.8-4.5-1.5c3.2-1.5,6.4-3,9.3-4.3c9.8-4.4,19.8-8.2,30.8-8.2c9.6,0,17.8,3.6,24.7,10.3c0.3,0.4,0.7,0.8,1,1.3
		c-3.6-0.3-3.8-0.2-5.6,0c-1.2,0.2-2.8,0.7-3.9,1.4c-1.2,0.7-1.8,1-3.2,2.2C-138.9,238.8-138.7,238.7-142,236.7"
                />
                <path
                    fill="#ED1C24"
                    d="M-192.9,221c8.9-1.2,22.5-0.8,31.2,2c-1.4,0.1-3,0.5-4.8,1c-7.5,2.6-15.1,5.2-22.5,8.1
		c-2.9,1.1-5.2,1.6-8.3-0.2c-6.9-4-14.7-5.5-22.5-6.4c-0.4,0-0.8-0.1-1.2-0.2c0-0.2-1.9-0.4-1.9-0.6c3.5,0,9-0.6,12.4-0.1
		c5.6,0.4,11,2.5,16.3,4.2c2,0.6,4.4-0.1,6.2-1.3c-5.8-3.2-15.1-3.7-20.9-5.7c0-0.3,1.8,0.1,3,0c4.4,0.4,8.9,0.7,13.3,1.3
		c2.4,0.3,4.7,0.9,7,1.8c2.6,0.9,3.7,0.8,6.3-1c-1.2-0.8-2.4-1-4.3-1.4C-185.4,222-191.2,221.4-192.9,221"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-192,241.6c-6.5,3-18.5,0.1-28.1-7.1c1.8,0.9,3.5,1.7,5.3,2.6c5.2,2.5,10.9,2.9,16.4,3.3c2.1,0,2.1-0.1,3-0.3
		C-194,240.7-192.4,241.4-192,241.6"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-121.6,240.9c-4.9-1.2-9.8,1.8-11,6.6c-1.2,4.9,1.8,9.8,6.6,11c4.9,1.2,9.8-1.8,11-6.6
		C-113.7,247-116.7,242.1-121.6,240.9 M-126.6,261.1c-6.3-1.6-10.1-8-8.6-14.2c1.6-6.3,8-10.1,14.2-8.6c6.3,1.6,10.1,8,8.6,14.2
		C-113.9,258.8-120.3,262.7-126.6,261.1"
                />
                <path
                    d="M-122.2,243.5c-3.4-0.9-6.9,1.2-7.8,4.7c-0.9,3.4,1.2,6.9,4.7,7.8c3.4,0.9,6.9-1.2,7.8-4.7
		C-116.7,247.8-118.8,244.3-122.2,243.5 M-125.5,256.8c-3.9-1-6.3-5-5.3-8.9c1-3.9,5-6.3,8.9-5.3c3.9,1,6.3,5,5.3,8.9
		C-117.7,255.4-121.6,257.8-125.5,256.8"
                />
            </g>
            <g id="logo_RM_x5F__x21_">
                <path
                    style={{ fill: '#000' }}
                    d="M-367.1,276.3h4.5v-8.5l4.1-17.4h-4l-1.3,6.9c-0.3,1.5-0.6,3.3-0.7,4.6h-0.1c-0.1-1.3-0.5-3.1-0.7-4.6l-1.3-6.9h-4.5
		l4,17.4V276.3z M-386.5,276.4h4.2l1.7-16.5h0l1.7,16.5h4.2l3.3-26h-4.1l-1,12.2c-0.2,2.1-0.2,3.4-0.4,5.5h0
		c-0.1-2.1-0.2-3.6-0.5-5.6l-1.3-12.2h-3.2l-1.5,12.3l-0.5,5.2c0-0.9,0-0.1,0,0.1c-0.1-2-0.2-3.8-0.3-5.4l-1.1-12.3h-4.4
		L-386.5,276.4z M-396.4,272.5c-1.5,0-1.8-2.3-1.8-9.1c0-6.8,0.2-9,1.8-9c1.5,0,1.8,2.2,1.8,9C-394.6,270.2-394.9,272.5-396.4,272.5
		 M-396.4,276.6c4.3,0,6.4-3.6,6.4-13.2c0-9.6-2.1-13.2-6.4-13.2c-4.3,0-6.4,3.6-6.4,13.2C-402.8,273-400.7,276.6-396.4,276.6
		 M-412.1,276.3h9.2v-3.8h-4.7v-22.1h-4.5V276.3z M-425.5,276.3h4.4v-8.9l0.6-2l2.5,10.8h4.5l-4-15.8l3.5-10.1h-3.9l-1.8,5.4
		c-0.6,1.7-1,3.5-1.5,5.5v-11h-4.4V276.3z M-435,276.3h4.5v-8.5l4.1-17.4h-4l-1.3,6.9c-0.3,1.5-0.6,3.3-0.7,4.6h-0.1
		c-0.1-1.3-0.5-3.1-0.7-4.6l-1.3-6.9h-4.5l4,17.4V276.3z M-444.6,276.6c4.9,0,5.7-4.4,6-9.7l-4.3-0.2c-0.1,4.8-0.5,5.8-1.7,5.8
		c-1.5,0-1.7-2.5-1.7-9.1c0-6.5,0.2-9,1.7-9c1.1,0,1.5,1.3,1.6,5.9l4.3-0.2c-0.1-7.7-1.7-9.9-5.8-9.9c-4.3,0-6.3,3.5-6.3,13.2
		C-450.9,273.1-448.9,276.6-444.6,276.6 M-458.5,272.5c-1.5,0-1.8-2.3-1.8-9.1c0-6.8,0.2-9,1.8-9c1.5,0,1.8,2.2,1.8,9
		C-456.8,270.2-457,272.5-458.5,272.5 M-458.5,276.6c4.3,0,6.4-3.6,6.4-13.2c0-9.6-2.1-13.2-6.4-13.2c-4.3,0-6.4,3.6-6.4,13.2
		C-464.9,273-462.8,276.6-458.5,276.6 M-472.9,276.3h4.6v-21.9h3.4v-4h-11.3v4h3.4V276.3z M-483.1,272.5c-1.5,0-1.8-2.3-1.8-9.1
		c0-6.8,0.2-9,1.8-9c1.5,0,1.8,2.2,1.8,9C-481.4,270.2-481.6,272.5-483.1,272.5 M-483.1,276.6c4.3,0,6.4-3.6,6.4-13.2
		c0-9.6-2.1-13.2-6.4-13.2c-4.3,0-6.4,3.6-6.4,13.2C-489.6,273-487.5,276.6-483.1,276.6 M-506.5,276.3h3.7v-11.6c0-1.9,0-4.3,0-5
		h0.1c0.2,2.4,0.4,3.8,0.6,5.4l1.7,11.2h2.3l1.7-11.3c0.2-1.4,0.4-3.5,0.6-5.4h0.1c0,1.4,0,3.2,0,5v11.6h4.3v-25.9h-5.4l-2.1,16.1h0
		l-2.1-16.1h-5.4V276.3z"
                />
                <path
                    fill="#ED1C24"
                    d="M-520.6,276.3h4.4v-8.9l0.6-2l2.5,10.8h4.5l-4-15.8l3.5-10.1h-3.9l-1.8,5.4c-0.6,1.7-1.1,3.5-1.5,5.5v-11h-4.4
		V276.3z M-531.8,276.3h9.5v-3.8h-4.9v-8.1h3.3v-3.7h-3.3v-6.3h4.8v-4h-9.3V276.3z M-545.9,276.3h3.9v-8.2c0-1.4,0-5.4-0.2-7h0.1
		c0.4,2.5,1.2,4.8,1.8,7l2.3,8.2h3.7v-25.9h-3.9v8c0,1.8,0,4.6,0.2,7.1h-0.1c-0.4-2.4-1-5-1.5-7.2l-2-7.9h-4.2V276.3z M-555.7,276.3
		h4.6v-8.5l4.1-17.4h-4l-1.3,6.9c-0.3,1.5-0.6,3.3-0.7,4.6h-0.1c-0.1-1.3-0.5-3.1-0.7-4.6l-1.3-6.9h-4.5l4,17.4V276.3z
		 M-567.1,263.6v-9.2h0.8c1.3,0,1.9,0.8,1.9,4.3c0,3.8-0.7,4.9-1.9,4.9H-567.1z M-571.6,276.3h4.4v-9.4h0.8l2.3,9.4h4.4l-2.8-10.9
		c1.5-1,2.4-3.3,2.4-6.8c0-5.9-2.8-8.2-6.3-8.2h-5.3V276.3z"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-391.8,236.8c-1.7-0.6,1.5,4.4,0.2,3.4c-5.4-4.2-15.4-14.5-21.1-18.2c-11.5-7.4-24.9-6.9-35,2.5
		c-15.4,14.2-33.3,16.3-52.6,13.4c-16.8-2.5-33.5-5.9-50.4-8c-7-0.9-13.6-0.4-22.1,0.8c7.8-4,15.9-5.2,23.9-4.4
		c14.2,1.4,28.4,3.7,42.6,5.3c6.4,0.7,13,1.1,19.5,0.9c9.7-0.2,18.4-3.2,26-9.5c4.2-3.4,8.9-7.3,13.6-10c13.5-7.7,26.8-7,39.8,2.2
		c3,2.1,6,4.3,8.9,6.5"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-417.4,207.2c3-3.6,5.8-6.7,8.8-10.3c-9.9-3.3-19.5-2.8-29.1,0.1c-15.1,4.5-27.7,13.7-40.6,22.3
		c-4.5,3-16.7,11.1-17.9,11.7c-3.1,0-4.9-0.3-8.8-0.7c5.2-4.2,10.2-8.4,14.9-12c15.8-12.4,32.2-23.7,52.1-28.7
		c17.4-4.3,33.8-1.5,49.3,7.5c0.7,0.5,1.6,1.1,2.4,1.9c-6.6,1.1-7,1.3-10.2,2.5c-2.2,0.9-4.8,2.6-6.4,4.2c-1.8,1.8-2.8,2.5-4.9,5.4
		C-410.8,209.7-410.6,209.4-417.4,207.2"
                />
                <path
                    fill="#ED1C24"
                    d="M-516.4,201.8c15.6-6.3,40.3-11.6,57.3-10.5c-2.6,0.9-5.1,2.3-8.2,4c-12.4,8.2-24.9,16.3-37,24.8
		c-4.8,3.3-8.8,5.2-15.1,3.4c-14.3-4.2-29-3.2-43.7-1.5c-0.8,0.1-1.5,0.1-2.3,0.2c-0.1-0.4-3.7,0.2-3.8-0.2c6.3-1.6,16-5.2,22.3-5.7
		c10.3-1.7,20.9-0.4,31.4,0.2c3.8,0.2,7.8-2.1,10.7-5.2c-11.8-3.1-29,0.1-40.3-0.9c-0.1-0.6,3.3-0.7,5.5-1.3
		c8.2-1.3,16.4-2.8,24.7-3.7c4.4-0.5,8.9-0.4,13.4,0.1c5.1,0.5,7.1-0.2,10.9-4.7c-2.6-0.9-4.8-0.7-8.4-0.6
		C-502.4,200.2-513.1,201.8-516.4,201.8"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-505.6,238.7c-10.4,8.3-33.4,8.5-54.1-0.2c3.6,0.8,7.1,1.5,10.7,2.3c10.6,2.2,20.9,0.4,31.2-1.5c3.8-1,3.7-1,5.4-1.8
		C-509.6,237.9-506.3,238.5-505.6,238.7"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-378.5,205.7c-9.3,0-16.9,7.6-16.9,16.9c0,9.3,7.6,16.9,16.9,16.9c9.3,0,16.9-7.6,16.9-16.9
		C-361.6,213.3-369.2,205.7-378.5,205.7 M-378.5,244.5c-12.1,0-21.9-9.8-21.9-21.9c0-12.1,9.8-21.9,21.9-21.9
		c12.1,0,21.9,9.8,21.9,21.9C-356.6,234.7-366.5,244.5-378.5,244.5"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-378.5,210.7c-6.6,0-12,5.4-12,12c0,6.6,5.4,12,12,12c6.6,0,12-5.4,12-12C-366.6,216-371.9,210.7-378.5,210.7
		 M-378.5,236.3c-7.5,0-13.6-6.1-13.6-13.6c0-7.5,6.1-13.6,13.6-13.6c7.5,0,13.6,6.1,13.6,13.6C-364.9,230.1-371,236.3-378.5,236.3"
                />
            </g>
            <g id="logo_RM">
                <path
                    style={{ fill: '#000' }}
                    d="M-231.8,45.4h4.6v-8.5l4.1-17.4h-4l-1.3,6.9c-0.3,1.5-0.6,3.3-0.7,4.6h-0.1c-0.1-1.3-0.5-3.1-0.7-4.6l-1.3-6.9h-4.5l4,17.4
		V45.4z M-251.2,45.4h4.2l1.7-16.5h0l1.7,16.5h4.2l3.3-26h-4.1l-1,12.2c-0.2,2.1-0.2,3.4-0.4,5.5h0c-0.1-2.1-0.2-3.6-0.5-5.6
		l-1.3-12.2h-3.2l-1.5,12.3l-0.5,5.2c0-0.9,0-0.1,0,0.1c-0.1-2-0.2-3.8-0.3-5.4l-1.1-12.3h-4.4L-251.2,45.4z M-261.1,41.6
		c-1.5,0-1.8-2.3-1.8-9.1c0-6.8,0.2-9,1.8-9c1.5,0,1.8,2.2,1.8,9C-259.3,39.2-259.5,41.6-261.1,41.6 M-261.1,45.6
		c4.3,0,6.4-3.6,6.4-13.2s-2.1-13.2-6.4-13.2c-4.3,0-6.4,3.6-6.4,13.2S-265.4,45.6-261.1,45.6 M-276.8,45.4h9.2v-3.8h-4.7V19.5h-4.5
		V45.4z M-290.2,45.4h4.4v-8.9l0.6-2l2.5,10.8h4.5l-4-15.8l3.5-10.1h-3.9l-1.8,5.4c-0.6,1.7-1,3.5-1.5,5.5v-11h-4.4V45.4z
		 M-299.7,45.4h4.5v-8.5l4.1-17.4h-4l-1.3,6.9c-0.3,1.5-0.6,3.3-0.7,4.6h-0.1c-0.1-1.3-0.5-3.1-0.7-4.6l-1.3-6.9h-4.5l4,17.4V45.4z
		 M-309.2,45.6c4.9,0,5.7-4.4,6-9.7l-4.3-0.2c-0.1,4.8-0.5,5.8-1.7,5.8c-1.5,0-1.7-2.5-1.7-9.1c0-6.5,0.2-9,1.7-9
		c1.1,0,1.5,1.3,1.6,5.9l4.3-0.2c-0.1-7.7-1.7-9.9-5.8-9.9c-4.3,0-6.3,3.5-6.3,13.2S-313.6,45.6-309.2,45.6 M-323.2,41.6
		c-1.5,0-1.8-2.3-1.8-9.1c0-6.8,0.2-9,1.8-9c1.5,0,1.8,2.2,1.8,9C-321.4,39.2-321.6,41.6-323.2,41.6 M-323.2,45.6
		c4.3,0,6.4-3.6,6.4-13.2s-2.1-13.2-6.4-13.2c-4.3,0-6.4,3.6-6.4,13.2S-327.5,45.6-323.2,45.6 M-337.6,45.4h4.5V23.4h3.4v-4H-341v4
		h3.4V45.4z M-347.8,41.6c-1.5,0-1.8-2.3-1.8-9.1c0-6.8,0.2-9,1.8-9c1.5,0,1.8,2.2,1.8,9C-346,39.2-346.3,41.6-347.8,41.6
		 M-347.8,45.6c4.3,0,6.4-3.6,6.4-13.2s-2.1-13.2-6.4-13.2c-4.3,0-6.4,3.6-6.4,13.2S-352.1,45.6-347.8,45.6 M-371.1,45.4h3.7V33.8
		c0-1.9,0-4.3,0-5h0.1c0.2,2.4,0.4,3.9,0.6,5.4l1.7,11.2h2.3l1.7-11.3c0.2-1.4,0.4-3.5,0.6-5.4h0.1c0,1.4,0,3.2,0,5v11.6h4.3V19.5
		h-5.4l-2.1,16.1h0l-2.1-16.1h-5.4V45.4z"
                />
                <path
                    fill="#ED1C24"
                    d="M-385.2,45.4h4.4v-8.9l0.6-2l2.5,10.8h4.5l-4-15.8l3.5-10.1h-3.9l-1.8,5.4c-0.6,1.7-1.1,3.5-1.5,5.5v-11h-4.4
		V45.4z M-396.4,45.4h9.5v-3.8h-4.9v-8.1h3.3v-3.7h-3.3v-6.3h4.8v-4h-9.3V45.4z M-410.5,45.4h3.9v-8.2c0-1.4,0-5.4-0.2-7h0.1
		c0.4,2.5,1.2,4.8,1.8,7l2.3,8.2h3.7V19.5h-3.9v8c0,1.8,0,4.6,0.2,7.1h-0.1c-0.4-2.4-1-5-1.5-7.2l-2-7.9h-4.2V45.4z M-420.4,45.4
		h4.5v-8.5l4.1-17.4h-4l-1.3,6.9c-0.3,1.5-0.6,3.3-0.7,4.6h-0.1c-0.1-1.3-0.5-3.1-0.7-4.6l-1.3-6.9h-4.5l4,17.4V45.4z M-431.8,32.6
		v-9.2h0.8c1.3,0,1.9,0.8,1.9,4.3c0,3.8-0.7,4.9-1.9,4.9H-431.8z M-436.2,45.4h4.4V36h0.8l2.3,9.4h4.4l-2.8-10.8
		c1.5-1,2.5-3.3,2.5-6.8c0-6-2.8-8.2-6.3-8.2h-5.3V45.4z"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-283.2,7.5c-1.2-0.4,1.1,3.1,0.1,2.4C-286.9,7-294-0.3-298-2.9c-8.1-5.2-17.5-4.9-24.7,1.7c-10.8,10-23.5,11.5-37.1,9.5
		c-11.9-1.8-23.6-4.1-35.5-5.6c-5-0.6-9.6-0.3-15.6,0.6c5.5-2.8,11.2-3.6,16.8-3.1c10,1,20,2.6,30,3.8c4.5,0.5,9.2,0.8,13.7,0.7
		c6.8-0.1,13-2.2,18.3-6.7c2.9-2.4,6.3-5.2,9.6-7c9.6-5.4,18.9-4.9,28,1.5c2.1,1.5,4.2,3,6.3,4.6"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-301.3-13.3c2.1-2.5,4.1-4.8,6.2-7.2c-7-2.3-13.7-2-20.5,0.1c-10.7,3.2-19.5,9.7-28.6,15.7c-3.2,2.1-11.8,7.8-12.7,8.3
		c-2.2,0-3.5-0.2-6.2-0.5c3.6-2.9,7.2-5.9,10.5-8.5c11.1-8.7,22.7-16.7,36.7-20.2c12.3-3,23.9-1.1,34.7,5.3c0.5,0.4,1.2,0.8,1.7,1.4
		c-4.6,0.8-4.9,0.9-7.2,1.8c-1.5,0.6-3.4,1.8-4.5,3c-1.3,1.3-2,1.8-3.5,3.8C-296.7-11.5-296.5-11.8-301.3-13.3"
                />
                <path
                    fill="#ED1C24"
                    d="M-371.1-17.1c11-4.4,28.4-8.2,40.4-7.4c-1.8,0.6-3.6,1.6-5.8,2.8c-8.7,5.8-17.5,11.5-26.1,17.5
		c-3.4,2.4-6.2,3.7-10.7,2.4c-10.1-3-20.5-2.3-30.8-1c-0.5,0.1-1.1,0.1-1.6,0.1c-0.1-0.3-2.6,0.1-2.7-0.2c4.4-1.2,11.3-3.7,15.7-4
		c7.2-1.2,14.8-0.3,22.1,0.1c2.7,0.1,5.5-1.5,7.5-3.6c-8.3-2.2-20.4,0.1-28.4-0.7c-0.1-0.4,2.3-0.5,3.8-0.9
		c5.8-0.9,11.6-1.9,17.4-2.6c3.1-0.4,6.3-0.3,9.4,0c3.6,0.4,5-0.1,7.7-3.3c-1.8-0.6-3.4-0.5-5.9-0.4
		C-361.2-18.2-368.8-17.1-371.1-17.1"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-363.5,8.9c-7.3,5.8-23.5,6-38.1-0.1c2.5,0.5,5,1.1,7.6,1.6c7.4,1.6,14.8,0.3,22-1.1c2.7-0.7,2.6-0.7,3.8-1.3
		C-366.3,8.3-364,8.7-363.5,8.9"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-273.9-14.4c-6.6,0-11.9,5.4-11.9,11.9c0,6.6,5.4,11.9,11.9,11.9c6.6,0,11.9-5.4,11.9-11.9C-262-9-267.3-14.4-273.9-14.4
		 M-273.9,13c-8.5,0-15.4-6.9-15.4-15.4c0-8.5,6.9-15.4,15.4-15.4s15.4,6.9,15.4,15.4C-258.5,6.1-265.4,13-273.9,13"
                />
                <path
                    style={{ fill: '#000' }}
                    d="M-273.9-10.9c-4.7,0-8.4,3.8-8.4,8.4c0,4.7,3.8,8.4,8.4,8.4c4.7,0,8.4-3.8,8.4-8.4C-265.5-7.1-269.3-10.9-273.9-10.9
		 M-273.9,7.2c-5.3,0-9.6-4.3-9.6-9.6c0-5.3,4.3-9.6,9.6-9.6c5.3,0,9.6,4.3,9.6,9.6C-264.3,2.8-268.6,7.2-273.9,7.2"
                />
            </g>
        </svg>
    );
}

type Brand = 'MM' | 'AE' | 'ET' | 'RM';

type Props = Partial<CustomIconComponentProps>;

const LogotypeIcon = ({ ...restProps }: Props): JSX.Element => {
    const { brand }: { brand: Brand } = getConfig().publicRuntimeConfig;
    switch (brand) {
        case 'MM':
            return <Icon component={MMSmallLogotypeSvg} {...restProps} />;
        case 'ET':
            return <Icon component={ETSmallLogotypeSvg} {...restProps} />;
        case 'RM':
            return <Icon component={RMSmallLogotypeSvg} {...restProps} />;
        case 'AE':
            return <Icon component={AESmallLogotypeSvg} {...restProps} />;
    }
};

export default LogotypeIcon;
